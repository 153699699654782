import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  fr;
  en;
  france;
  english;
  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    if(window.localStorage.getItem('fr')) {
      console.log('ifffff/..');
    } if(window.localStorage.getItem('en')) {
      window.localStorage.removeItem('fr');
    }
     else { 
      window.localStorage.setItem('fr', 'fr');
    }
    this.france = window.localStorage.getItem('fr');
    // console.log(this.france);
    
    this.english = window.localStorage.getItem('en');
    // console.log(this.english);
  }

  ngOnInit(): void {
    if(this.english == 'en') {
      this.en = true;
      this.fr = false;
    } if(this.france == 'fr'){
      this.en = false;
      this.fr = true;
    }
  }

}
