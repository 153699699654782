import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  getallcountry(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `country/list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallcountry')));
  }

  getallcities(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `city/list/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallcities')));
  }

  aboutusactivedata() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `aboutus/active_data`, { headers });
  }
  privacyctivedata() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `privacypolicy/active_data`, { headers });
  }
  termsactivedata() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `termsofservice/active_data`, { headers });
  }

  pricingdata() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `pricing/active_data`, { headers });
  }

  createmanager(managerdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/register`, managerdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createmanager')));
  }

  resentOtp(body): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/resend_otp`, body, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createmanager')));
  }
}
