import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from '../services/cms.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalNoticeComponent } from '../legal-notice/legal-notice.component';
declare var $: any;
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  @ViewChild('otpModal', { static: false }) otpModal: ElementRef;
  showOTPInput: boolean = false; // Initially, OTP input is hidden
  formheadertitle = 'Add Manager';
  id: any;
  managerForm: FormGroup;
  managerroleList: any[];
  selectedrole: any;
  accessList: any[];
  selectedaccess: any;
  fieldTextType1 = false;
  fieldTextType2 = false;
  isEnabled = false;

  countryList: any[];
  selectedcountry: any;
  citiesList: any[];
  selectedcity: any;

  showOTPEntry: boolean = false;
  otp: string = '';

  // for contact number
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  form_otp:any;
  isResendOTP: boolean = false;
  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;
  constructor(
    public fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private managerservice: CmsService,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public modal: NgbActiveModal,
    public renderer: Renderer2,
    public modalService: NgbModal,
     private el: ElementRef

  ) {

  }

  openSignatureModal() {


    const modalRef = this.modalService.open(LegalNoticeComponent, { size: 'sm', backdrop: 'static' });

    modalRef.result.then((result) => {
      if (result) {
        console.log('Data received from the modal:', result);

      } else {
        console.log('Modal closed without data.');
      }
    });
  }

  openOTPModal() {
    this.renderer.addClass(this.otpModal.nativeElement, 'show');
  }

  closeOTPModal() {
    this.renderer.removeClass(this.otpModal.nativeElement, 'show');
  }

  // tslint:disable-next-line:typedef
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  // tslint:disable-next-line:typedef
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }
  // only number will be add
  // tslint:disable-next-line:typedef
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    // tslint:disable-next-line:prefer-const
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  verifyOTP() {
    // Add OTP verification logic here
    // Once OTP is verified, set showOTPInput to true and isResendOTP to true
    this.showOTPInput = true;
    this.isResendOTP = true;
  }
  ngOnInit(): void {
    this.countrylist();
    this.managerForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      last_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      email_id: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      contact_number: ['', [Validators.required]],
      country: ['', [Validators.required]],
      countryidref: [],
      city: ['', [Validators.required]],
      cityid_ref: [],
      address: ['', [Validators.required]],
      company_name: ['', [Validators.required]],
      title: [''],
      otp: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required]
    }, { validators: this.passwordMatchValidator })

  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  togglePasswordConfirmVisibility() {
    this.passwordConfirmVisible = !this.passwordConfirmVisible;
  }
  
  // Custom password matching validator
  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password.value !== confirmPassword.value) {
      // If passwords don't match, set a custom error on the confirmPassword control
      confirmPassword.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      // If passwords match, clear the error on the confirmPassword control
      confirmPassword.setErrors(null);
      return null;
    }
  }
  onInputNumber(event: any) {
    console.log("event",event)
    const input = event.target;
    const value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    input.value = value; // Update the input value
    this.otp = value; // Update the model with the filtered value
  }

  // tslint:disable-next-line:typedef
  countrylist() {
    this.ngxLoader.start();
    this.managerservice.getallcountry().subscribe(res => {
      console.log(res);
      if (res.status === true) {
        this.countryList = res.countries;
        this.ngxLoader.stopAll();
        console.log(this.countryList);
      } else {
        this.ngxLoader.stopAll();
        this.translateService.get('app').subscribe((translate) => {
          Swal.fire({
            icon: 'error',
            title: translate.oops,
            text: res.message
          });
        });

      }
    }, error => {
      this.ngxLoader.stopAll();
      this.translateService.get('app').subscribe((translate) => {
        Swal.fire({
          icon: 'error',
          title: translate.oops,
          text: translate.error_msg
        });
      });
      console.error(error);
    });
  }

  // tslint:disable-next-line:typedef
  changecountry(e) {
    this.managerForm.controls.countryidref.setValue(Number(this.selectedcountry));
    this.managerForm.get('country').setValue(e.target.value, {
      onlySelf: true
    });
    console.log(this.selectedcountry);
    this.citylist();
  }

  // tslint:disable-next-line:typedef
  citylist() {
    this.ngxLoader.start();
    this.managerservice.getallcities(this.selectedcountry).subscribe(res => {
      console.log(res);
      if (res.status === true) {
        this.citiesList = res.cities;
        this.ngxLoader.stopAll();
        console.log(this.citiesList);
      } else {
        this.ngxLoader.stopAll();
        this.translateService.get('app').subscribe((translate) => {
          Swal.fire({
            icon: 'error',
            title: translate.oops,
            text: res.message
          });
        });
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.translateService.get('app').subscribe((translate) => {
        Swal.fire({
          icon: 'error',
          title: translate.oops,
          text: translate.error_msg
        });
      });
      console.error(error);
    });
  }

  // tslint:disable-next-line:typedef
  changecity(e) {
    this.managerForm.controls.cityid_ref.setValue(Number(this.selectedcity));
    this.managerForm.get('city').setValue(e.target.value, {
      onlySelf: true
    });
  }
  // tslint:disable-next-line:typedef
  changerole(e) {
    this.managerForm.controls.realestatemanagerrole_id_ref.setValue(Number(this.selectedrole));
    this.managerForm.get('role').setValue(e.target.value, {
      onlySelf: true
    });
  }
  // tslint:disable-next-line:typedef
  // changeaccess(e) {
  //   this.managerForm.controls.access_id_ref.setValue(Number(this.selectedaccess));
  //   this.managerForm.get('access').setValue(e.target.value, {
  //     onlySelf: true
  //   });
  // }


  sentOtp() {
    if(this.managerForm.controls.contact_number.value.e164Number){
    this.showOTPInput = true;
    this.isResendOTP = true;
    this.ngxLoader.start();
    let body = {
      contact_number: this.managerForm.controls.contact_number.value.e164Number
    }
    this.managerservice.resentOtp(body).subscribe(res => {
      console.log(res);
      if (res.status === true) {
        console.log(res);
        this.otp = res.otp;
        this.ngxLoader.stopAll();

        Swal.fire('', res.message, 'success');
        console.log(res);

      } else {
        this.ngxLoader.stopAll();
        console.log(res);
        this.translateService.get('app').subscribe((translate) => {
          Swal.fire({
            icon: 'error',
            title: translate.oops,
            text: res.message
          });
        });
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.translateService.get('app').subscribe((translate) => {
        Swal.fire({
          icon: 'error',
          title: translate.oops,
          text: translate.error_msg
        });
      });
      console.error(error);
    });
  }
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    if (this.otp == this.form_otp) {


      this.ngxLoader.start();
      let phone_number = this.managerForm.controls.contact_number.value.e164Number;
      const updatedvalue = {
        first_name: this.managerForm.controls.first_name.value,
        last_name: this.managerForm.controls.last_name.value,
        email_id: this.managerForm.controls.email_id.value,
        contact_number: phone_number,
        title: this.managerForm.controls.title.value,
        company_name: this.managerForm.controls.company_name.value,
        country_id_refer: this.managerForm.controls.countryidref.value,
        city_id_refer: this.managerForm.controls.cityid_ref.value,
        address: this.managerForm.controls.address.value,
        password: this.managerForm.controls.password.value,
        otp:this.otp
      };



      console.log('form value at update', updatedvalue);

      this.managerservice.createmanager(updatedvalue).subscribe(res => {
        console.log(res);
        if (res.status === true) {
          console.log(res);
          this.ngxLoader.stopAll();
          this.modal.close();
          Swal.fire('', res.message, 'success');
          console.log(res);

        } else {
          this.ngxLoader.stopAll();
          console.log(res);
          this.translateService.get('app').subscribe((translate) => {
            Swal.fire({
              icon: 'error',
              title: translate.oops,
              text: res.message
            });
          });
        }
      }, error => {
        this.ngxLoader.stopAll();
        this.translateService.get('app').subscribe((translate) => {
          Swal.fire({
            icon: 'error',
            title: translate.oops,
            text: translate.error_msg
          });
        });
        console.error(error);
      });
    } else {
      this.translateService.get('app').subscribe((translate) => {
        Swal.fire({
          icon: 'error',
          title: translate.title1,
          text: translate.text1
        });
      });
    }
  }

  openTerms(){
    this.modal.close();
   this.router.navigate(['/terms-and-condition']);
  }

  openPrivacy(){
    this.modal.close();
    this.router.navigate(['/privacy-policy']);
  }

  // Customer Validation Function
  // tslint:disable-next-line:typedef
  get f() {
    return this.managerForm.controls;
  }

}
