import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';

@Component({
  selector: 'app-about-alkira',
  templateUrl: './about-alkira.component.html',
  styleUrls: ['./about-alkira.component.css']
})
export class AboutAlkiraComponent implements OnInit {
  data: any;
  title: any;
  description: any;
  constructor(
    private cmsservice: CmsService
  ) { }

  ngOnInit(): void {
    this.cmsservice.aboutusactivedata().subscribe((res: any) => {
      console.log(res);
      if(res.status == true){
        this.data = res.aboutus;
        console.log(this.data);
        this.title = this.data[0].aboutus_data;
        console.log(this.title);
        this.description = this.data[0].aboutus_description;
      } else {
        console.log('.....')
      }
    })
  }

}
