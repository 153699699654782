<!-- custom-modal.component.html -->
<ngx-ui-loader></ngx-ui-loader>
<div class="modal-header">
  <h4 class="modal-title">{{ 'Real Estate Manager' | translate }}</h4>
  <button type="button" class="close newclose" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="managerForm">

    <div style="display: flex; width: 100%;">
      <div class="form-group" style="flex: 1;">
        <label for="first_name" class="control-label">
          <b>{{ 'First Name' | translate }} <span style="color: red;">*</span></b>
        </label>
        <input type="text" class="form-control" formControlName="first_name" id="first_name">
        <div *ngIf="f.first_name.touched && f.first_name.invalid" class="small form-text text-danger">
          <div *ngIf="f.first_name.errors.required">{{ 'First Name is required' | translate }}!</div>
          <div *ngIf="f.first_name.errors.pattern">{{ 'Please enter a valid First Name' | translate }}!</div>
        </div>
      </div>

      <div class="form-group" style="flex: 1;margin-left: 5px;">
        <label for="last_name" class="control-label">
          <b>{{ 'Last Name' | translate }} <span style="color: red;">*</span></b>
        </label>
        <input type="text" class="form-control" id="last_name" formControlName="last_name">
        <div *ngIf="f.last_name.touched && f.last_name.invalid" class="small form-text text-danger">
          <div *ngIf="f.last_name.errors.required">{{ 'Last Name is required' | translate }}!</div>
          <div *ngIf="f.last_name.errors.pattern">{{ 'Please enter a valid Last Name' | translate }}!</div>
        </div>
      </div>
    </div>



    <div class="form-group">
      <label for="email_id" class="control-label">
        <b>{{ 'Email Address' | translate }} <span style="color: red;">*</span></b>
      </label>
      <input [readonly]="this.isEnabled" type="text" class="form-control" id="email_id" formControlName="email_id">
      <div *ngIf="f.email_id.touched && f.email_id.invalid" class="small form-text text-danger">
        <div *ngIf="f.email_id.errors.required">{{ 'Email Address is required' | translate }}!</div>
        <div *ngIf="f.email_id.errors.pattern">{{ 'Please enter a valid email address' | translate }}!</div>
      </div>
    </div>

    <input type="number" class="form-control" formControlName="countryidref" value="{{selectedcountry}}" hidden>
    <div class="form-group">
      <label for="country" class="control-label">
        <b>{{ 'SelectCountry' | translate }} <span style="color: red;">*</span></b>
      </label>
      <select class="form-control" id="country" formControlName="country" [(ngModel)]="selectedcountry"
        (change)="changecountry($event)">

        <option *ngFor="let countries of countryList" [value]="countries.id">{{countries.country_name}}</option>
      </select>
      <div *ngIf="f.country.touched && f.country.invalid" class="small form-text text-danger">
        <div *ngIf="f.country.errors.required">{{ 'Country is required' | translate }}</div>
      </div>
    </div>

    <input type="number" class="form-control" formControlName="cityid_ref" value="{{selectedcity}}" hidden>
    <div class="form-group">
      <label for="city" class="control-label">
        <b>{{ 'SelectCity' | translate }} <span style="color: red;">*</span></b>
      </label>
      <select class="form-control" id="city" formControlName="city" [(ngModel)]="selectedcity"
        (change)="changecity($event)">

        <option *ngFor="let cities of citiesList" [value]="cities.id">{{cities.city_name}}</option>
      </select>
      <div *ngIf="f.city.touched && f.city.invalid" class="small form-text text-danger">
        <div *ngIf="f.city.errors.required">{{ 'City is required' | translate }}</div>
      </div>
    </div>

    <!-- Add more form groups for other fields like address, password, company name, contact number, etc. -->

    <div class="form-group">
      <label for="address" class="control-label">
        <b>{{ 'Address' | translate }} <span style="color: red;">*</span></b>
      </label>
      <input type="text" class="form-control" id="address" formControlName="address">
      <div *ngIf="f.address.touched && f.address.invalid" class="small form-text text-danger">
        <div *ngIf="f.address.errors.required">{{ 'Address is required' | translate }}!</div>
        <div *ngIf="f.address.errors.pattern">{{ 'Please enter a valid Address' | translate }}!</div>
      </div>
    </div>

    <div class="form-group">
      <label for="password" class="control-label">
        <b>{{ 'Password' | translate }} <span style="color: red;">*</span></b>
      </label>
      <div class="input-group">
        <input [type]="passwordVisible ? 'text' : 'password'" class="form-control" id="password"
          formControlName="password">
        <div class="input-group-append" style="margin-top: 3px !important;">
          <span class="input-group-text" style="padding: 10px;
            border-left: 0px solid;
            border-radius: 0px!important;" (click)="togglePasswordVisibility()">
            <i class="fas" [ngClass]="passwordVisible ? 'fas fa-eye-slash' : 'fa-eye'"></i>
          </span>
        </div>
      </div>
      <div *ngIf="f.password.touched && f.password.invalid" class="small form-text text-danger">
        <div *ngIf="f.password.errors.required">{{ 'Password is required' | translate }}!</div>
        <div *ngIf="f.password.errors.pattern">{{ 'Please enter a valid Password' | translate }}!</div>
      </div>
    </div>

    <div class="form-group">
      <label for="confirmPassword" class="control-label">
        <b>{{ 'Confirm Password' | translate }} <span style="color: red;">*</span></b>
      </label>
      <div class="input-group">
        <input [type]="passwordConfirmVisible ? 'text' : 'password'" class="form-control" id="confirmPassword"
          formControlName="confirmPassword">
        <div class="input-group-append" style="margin-top: 3px !important;">
          <span class="input-group-text" style="padding: 10px;
            border-left: 0px solid;
            border-radius: 0px!important;" (click)="togglePasswordConfirmVisibility()">
            <i class="fas" [ngClass]="passwordConfirmVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
          </span>
        </div>
      </div>
      <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="small form-text text-danger">
        <div *ngIf="f.confirmPassword.errors.required">{{ 'Confirm Password is required' | translate }}!</div>
        <div *ngIf="f.confirmPassword.errors.passwordMismatch">{{ 'Passwords do not match' | translate }}!</div>
      </div>
    </div>


    <div class="form-group">
      <label for="company_name" class="control-label">
        <b>{{ 'Company Name' | translate }} <span style="color: red;">*</span></b>
      </label>
      <input type="text" class="form-control" id="company_name" formControlName="company_name">
      <div *ngIf="f.company_name.touched && f.company_name.invalid" class="small form-text text-danger">
        <div *ngIf="f.company_name.errors.required">{{ 'Company Name is required' | translate }}!</div>
        <div *ngIf="f.company_name.errors.pattern">{{ 'Please enter a valid Company Name' | translate }}!</div>
      </div>
    </div>


    <div class="form-group">
      <label for="contact_number" class="control-label">
        <b>{{ 'Contact Number' | translate }} <span style="color: red;">*</span></b>
      </label>
      <!-- <div style="display: flex;">
        <ngx-intl-tel-input class="form-control " [cssClass]="'custom'" [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.Morocco" [phoneValidation]="true"
        [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="contact_number"
        formControlName="contact_number">
      </ngx-intl-tel-input>


      
      </div> -->


      <div class="input-group">
        <ngx-intl-tel-input class="form-control " [cssClass]="'custom'" [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
          [selectedCountryISO]="CountryISO.Morocco" [phoneValidation]="true" [separateDialCode]="separateDialCode"
          [numberFormat]="PhoneNumberFormat.National" name="contact_number" formControlName="contact_number">
        </ngx-intl-tel-input>
        <div class="input-group-append" style="margin-top: 0px !important;">
          <span class="input-group-text" style="padding: 11px;
          border-left: 0px solid;
          border-radius: 0px!important;">
            <label *ngIf="isResendOTP==true" (click)="sentOtp()">
              {{'Resend OTP' |translate}}</label>
            <label *ngIf="isResendOTP==false" (click)="sentOtp()">
              {{'Send OTP' |translate}}</label>
          </span>
        </div>
      </div>

      <div *ngIf="f.contact_number.touched && f.contact_number.invalid" class="small form-text text-danger">
        <div *ngIf="f.contact_number.errors.required">{{ 'Contact Number is required' | translate }}!</div>
        <div *ngIf="f.contact_number.valid == false">{{ 'Enter a valid Mobile Number' | translate }}!</div>
      </div>
    </div>

    <div *ngIf="showOTPInput">
      <div class="form-group">
        <label for="otpInput">{{'OTP' | translate}}</label>
        <input type="number" (keypress)="onInputNumber($event)" class="form-control" [(ngModel)]="form_otp"
          formControlName="otp" [placeholder]="'Enter OTP' | translate">
      </div>
      <div *ngIf="f.otp.touched && f.otp.invalid" class="small form-text text-danger">
        <div *ngIf="f.otp.errors.required">{{ 'Otp is required' | translate }}!</div>
      </div>
    </div>

    <hr>

    <div style="text-align: center;">
      <button type="submit" ngbAutofocus style="background: #7C1F6A; color: white;" class="btn btn-round text-center"
        [disabled]="managerForm.invalid" (click)="onSubmit()">
        {{ 'Verify and Submit' | translate }}
      </button>
    </div>

  </form>
  <br>
  <div>
    {{'trem1' | translate}} <b style="color: blue;cursor: pointer;" (click)="openTerms()"> {{'Terms of Use' |
      translate}} </b> {{'and' | translate}} <b style="color: blue;cursor: pointer;" (click)="openPrivacy()">{{'the
      Privacy Policy' | translate}} </b> .
  </div>

</div>