import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';
 import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  data: any;
  title: any;
  image_url:any;
  description: any;
  check_title:any
  constructor(
    private cmsservice: CmsService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.ngxLoader.start()
    this.cmsservice.aboutusactivedata().subscribe((res: any) => {
      console.log(res);
      this.ngxLoader.stop()
      if(res.status == true){
      
        this.data = res.aboutus;
        console.log(this.data);
        if(this.data.length!=0){
          this.check_title='yes';
          this.title = this.data[0].aboutus_data;
          console.log(this.title);
          this.description = this.data[0].aboutus_description;
          this.image_url=this.data[0].image_url;
        }else{
          this.check_title='no';
        }
            
      } else {
        this.check_title='no';
        console.log('.....')
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.check_title='no';
      console.error(error);
    });
  }


}
