<footer class="bg_lign_yellow ">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="card">
          <div class="card-body">
            <img style="margin-bottom: 50px;" src="../../assets/img/alkira_logo.svg" alt="logo">
            <h5 class="card-title mt-3 mb-3">{{'About Alkira'|translate}}</h5>
            <p class="card-text">{{'plateforme' | translate}}</p>
          </div>
          <div class="social_link">
            <ul class="listing hor">
              <li><a href="https://web.facebook.com/profile.php?id=100081021907615" 
                  rel="noopener noreferrer"><i class="bi bi-facebook spin"></i></a>
              </li>
              <li><a href="http://"  rel="noopener noreferrer"><i class="bi bi-linkedin"></i></a> </li>
              <li><a href=" https://www.instagram.com/alkira.digital/?hl=en" 
                  rel="noopener noreferrer"><i class="bi bi-instagram"></i></a>
              </li>
              <li><a href="http://"  rel="noopener noreferrer"><i class="bi bi-youtube"></i></a> </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mt-3 mb-3">{{'Useful Links' | translate}}</h4>
            <div class="user_link">
              <ul class="listing">
                <li><a [routerLink]="['/']"  rel="noopener noreferrer">{{'Home' | translate}}</a> </li>
                <li><a [routerLink]="['/about-us']"  rel="noopener noreferrer">{{'About Us' | translate}}</a> </li>
                <li><a [routerLink]="['/terms-and-condition']"  rel="noopener noreferrer">{{'Terms Conditions' | translate}}</a> </li>
                <!-- <li><a [routerLink]="['/terms-of-services']"  rel="noopener noreferrer">Terms of
                    Services</a> </li> -->
                <li><a [routerLink]="['/privacy-policy']"  rel="noopener noreferrer">{{'Privacy Policy' | translate}}</a>
                <!-- </li>
                <li><a [routerLink]="['/legal-notice']"  rel="noopener noreferrer">Legal Notice</a> </li> -->
              </ul>
            </div>
          </div>

        </div>

      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-end">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mt-3 mb-3">{{'Address' | translate}}</h4>
            <img src="../../assets/img/640px-Logo_STATION_F.svg.png" style="height: 27px; width: 170px;">
            <div class="address">
              <ul class="listing">
                <!-- <li><a href="http://"  rel="noopener noreferrer"><span class="mr-1 d-inline-block"><i
                          class="bi bi-alarm"></i></span> <span>Sun - Fri: 8.00am - 6.00pm</span></a> </li> -->
                <li><a href="https://www.google.com/maps/place/STATION+F/@48.8344594,2.3707613,17z/data=!3m1!4b1!4m5!3m4!1s0x47e67222208823cf:0x541ecb63365de48!8m2!3d48.8344594!4d2.3707613"  rel="noopener noreferrer"><span class="mr-1 d-inline-block"><i
                        class="bi bi-geo-alt"></i></span> <span>STATION F, 5 Parv. Alan Turing <br> <span
                        style="padding: 9%;"> 75013,Paris, France</span> </span></a> </li>
                <li><a href="tel:+33772063810"  rel="noopener noreferrer"><span
                      class="mr-1 d-inline-block"><i class="bi bi-telephone"></i></span> <span>+33772063810</span></a>
                </li>
                <li><a href="mailto:contact@alkira.digital"  rel="noopener noreferrer"><span
                      class="mr-1 d-inline-block"><i class="bi bi-envelope-open"></i></span>
                    <span>contact@alkira.digital</span></a> </li>
                <li>
                  <a href="mailto:contact@alkira.digital" rel="noopener noreferrer" class="btn" 
                    style="background-color: #7C1F6A;
                    color: #ffffff !important;
                    outline: none;
                    box-shadow: none;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    width: 180px;">
                    {{'Contact Us' | translate}}</a>
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</footer>