import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  data: any;
  title: any;
  image_url: any;
  description: any;
  check_title: any;
  constructor(
    private cmsservice: CmsService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.ngxLoader.start();
    this.cmsservice.pricingdata().subscribe((res: any) => {
      console.log(res);
      this.ngxLoader.stopAll();
      if (res.status == true) {
        this.data = res.pricing;
        if (this.data.length != 0) {
          console.log(this.data);
          this.title = this.data[0].pricing_data;
          console.log(this.title);
          this.description = this.data[0].pricing_description;
          this.image_url = this.data[0].image_url;
        } else {
          this.check_title = 'no';
        }
      } else {
        this.check_title = 'no';
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.check_title = 'no';
      console.error(error);
    });
  }


}
