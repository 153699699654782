<main>
    <app-header></app-header>
    <div class="inner-banner inner-bg1">
      <div class="container">
        <div class="inner-title">
          <h3>About Alkira</h3>
          <ul>
            <li>
              <a [routerLink]="['/']">Home</a>
            </li>
            <li>About Alkira</li>
          </ul>
        </div>
      </div>
    </div>
    <section class="bg_light section_padding">
      <div class="d-flex justify-content-center" *ngIf="!title">
        <button class="btn btn-danger" type="button" disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
      <div class="container">
        <div class="detail-content">
          <h3>{{title}}</h3>
          <p [innerHTML]='description'>
            {{description}}
          </p>
        </div>
      </div>
    </section>
  
  
    <app-footer></app-footer>
  </main>
