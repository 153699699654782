import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  france;
  english;
  frr;
  enn;
  selectedLang:any;
  constructor(
    private router: Router,
    public modalService:NgbModal

  ) {
    // if(window.localStorage.getItem('fr')) {
    //   console.log('ifffff/..');
    // } if(window.localStorage.getItem('en')) {
    //   window.localStorage.removeItem('fr');
    // }
    //  else { 
    //   window.localStorage.setItem('fr', 'fr');
    // }

  
    
    // this.english = window.localStorage.getItem('en');
    // console.log(this.english);

   }

  ngOnInit(): void {
    this.selectedLang = window.localStorage.getItem('app_language');
    console.log(this.selectedLang );
    // if(this.english == 'en') {
    //   this.enn = true;
    //   this.frr = false;
    // } if(this.france == 'fr'){
    //   this.enn = false;
    //   this.frr = true;
    // }
  }

  en() { 
   
    window.localStorage.setItem('app_language', 'en');
    window.location.reload();
  }

  fr() {
    // window.localStorage.setItem('fr', 'fr');
    window.localStorage.setItem('app_language', 'fr');
          window.location.reload();
  }

  openSignatureModal() {

    
    const modalRef = this.modalService.open(SignUpComponent, {   centered: true, // Center the modal vertically and horizontally
    backdrop: 'static', // Prevent dismissing the modal by clicking outside
   // Adjust size as needed, e.g., 'sm', 'lg', 'xl' 
  });
   
    modalRef.result.then((result) => {
      if (result) {
        console.log('Data received from the modal:', result);
       
      } else {
        console.log('Modal closed without data.');
      }
    });
  }

}
