import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.css']
})
export class TermsAndConditionComponent implements OnInit {

  data: any;
  title: any;
  description: any;
  check_title: any;
  constructor(
    private cmsservice: CmsService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.ngxLoader.start();
    this.cmsservice.termsactivedata().subscribe((res: any) => {
      console.log(res);
      this.ngxLoader.stopAll();
      if(res.status == true){
        this.data = res.termsofservice;
        if (this.data.length != 0) {
        console.log(this.data);
        this.title = this.data[0].termsandservice_data;
        console.log(this.title);
        this.description = this.data[0].termsandservice_description;
        }else {
          this.check_title = 'no';
        }
      } else {
        this.check_title = 'no';
      }
    }, error => {
      this.ngxLoader.stopAll();
      this.check_title = 'no';
      console.error(error);
    });
  }

}
