<header class="bg_light header_padding">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <a [routerLink]="['/']" style="cursor: pointer;">
        <img src="../../assets/img/alkira_logo.svg" alt="logo">
      </a>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 dfc">
        <nav class="navbar navbar-expand-lg  w-100">
            <a class="navbar-brand" href="#">{{'Menu' | translate}}</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-center">
                
              </ul> -->
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end align-items-center">
                <li class="nav-item" >
                  <a class="nav-link active" aria-current="page" [routerLink]="['/']">{{'Home' | translate}}</a>
                </li>
                <li class="nav-item" >
                  <a class="nav-link active" aria-current="page" [routerLink]="['/about-us']">{{'About Us' | translate}}</a>
                </li>
               
                <!-- <li class="nav-item" >
                  <a class="nav-link active" aria-current="page" [routerLink]="['/about-alkira']">{{'About Alkira' | translate}}</a>
                </li> -->
               
                <li class="nav-item" >
                  <a class="nav-link active" aria-current="page" [routerLink]="['/pricing']">{{'Pricing' | translate}}</a>
                </li>

                <li class="nav-item dropdown" >
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" class="nav-btn-two">
                {{'Login As' | translate}}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="https://tenants.espltestingsite.in/" target="_blank">{{'Tenant' | translate}}</a></li>
                  <li><a class="dropdown-item" style="white-space: break-spaces !important;" href="https://realestatemanager.espltestingsite.in" target="_blank">
                    {{'Real Estate Manager' | translate}}</a></li>
                 
                </ul>
              </li>

              <li class="nav-item" (click)="openSignatureModal()"  >
                  <a class="nav-link dropdown-toggle"  role="button" class="nav-btn-two">
                   {{'Sign Up' | translate}}
                  </a>
              </li>
             
              <li class="nav-item dropdown">
                <div class="dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../assets/img/world-16.png" alt="" srcset="">
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li [ngClass]="{ 'selected': selectedLang == 'en' }">
                      <a class="dropdown-item" href="javascript:void(0)" (click)="en()" >{{'English' | translate}}</a>
                    </li>
                    <li [ngClass]="{ 'selected': selectedLang == 'fr' }">
                      <a class="dropdown-item" href="javascript:void(0)" (click)="fr()" >{{'French' | translate}}</a>
                    </li>
                  </ul>
                </div>
              </li>
              
              </ul>
            </div>
        </nav>
      </div>
    </div>
  </div>
</header>
