
<ngx-ui-loader></ngx-ui-loader>
<main>
    <app-header></app-header>
    <div class="inner-banner inner-bg1">
      <div class="container">
        <div class="inner-title">
          <h3>{{'Pricing' | translate}}</h3>
          <ul>
            <li>
              <a [routerLink]="['/']">{{'Home' | translate}}</a>
            </li>
            <li>{{'Pricing' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <section class="bg_light section_padding">
   
      <div class="container">
        <div class="detail-content">
          <h3>{{title}}</h3>
          <p [innerHTML]='description'>
            {{description}}
          </p>
          <img src="../assets/img/pricing.png" alt="" class="img-fluid">
        </div>
      </div>
    </section> -->
  
    <section class="bg_light section_padding">
      <div class="d-flex justify-content-center" *ngIf="check_title=='no'">
        <button class="btn btn-danger" type="button" disabled>
          <span aria-hidden="true"></span>
          {{'NoData' | translate}}
        </button>
      </div>
      <div class="container"> 
        <div class="detail-content">

          <h3>{{title}}</h3>
          <p [innerHTML]='description'>
            {{description}}
          </p>

          <div style="text-align: center"><img [src]="image_url" class="img-fluid"></div>
          
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </main>
