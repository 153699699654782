<main>
    <app-header></app-header>
    <!-- <section class="bg_light banner_padding"> -->
    <section class="section_padding property_features">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-10 col-sm-12">
            <!-- <div class="big_imgf"> -->
              <div class="img">
                <img src="../assets/img/360_real_etate.png" alt="" class="img-fluid">
              </div>
              &nbsp;
            <!-- </div> -->
            <div class="banner_content">
              <h3  style="text-align: center"> 
                <span style="color: #7C1F6A;">
                  alkira.digital
                </span>
                {{'plateforme' | translate}} </h3>
             
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section_padding property_features">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-7 col-sm-6 px-lg-5">
            <div class="section_title">
              <h2  style="color:rgb(45, 58, 121);">{{'plateforme1' | translate}} </h2>
              
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12">
            <!-- <div class="big_img"> -->
              <div class="img">
                <img src="../assets/img/one_single_safe.png" alt="" class="img-fluid">
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="section_padding property_features">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="property-features-img">
              <img src="../assets/img/increase_your_benefits.png" alt="Images" class="img-fluid">
              <div class="shape">
                <!-- <img src="../assets/img/shape.png" alt="Images"> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 px-lg-5">
            <div class="section_content">
              <div class="section_title">
                <h2  style="color:rgb(45, 58, 121);">{{'plateforme2' | translate}}</h2>
                
              </div>
            </div>
            <!-- <div class="row mt-5">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <img src="../assets/img/duplex.png" class="card-img-top" alt="...">
                    <h4 class="card-title mt-3">Duplex Layout</h4>
                    <p class="card-text mt-3 mb-2">We have a lot of duplex and luxury rooms for you and you can easily buy
                      this.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <img src="../assets/img/verified.png" class="card-img-top" alt="...">
                    <h4 class="card-title mt-3">High-Level Security</h4>
                    <p class="card-text mt-3 mb-2">We have a lot of duplex and luxury rooms for you and you can easily buy
                      this.</p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
  
    </section>
    <section class="section_padding">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-12 px-lg-5">
            <div class="section_content">
              <div class="section_title">
                <h2  style="color:rgb(45, 58, 121);">{{'plateforme3' | translate}}</h2>
               
              </div>
            </div>
          </div>
          <div class="col-kg-6 col-md-6 col-sm-12">
            <!-- <div class="employee-img"> -->
              <img src="../assets/img/reduce_your_costs.png" alt="Images" class="img-fluid">
            <!-- </div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="section_padding property_features">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="property-features-img">
              <img src="../assets/img/improve_your_customer.png" alt="Images" class="img-fluid">
              <div class="shape">
                <!-- <img src="../assets/img/shape.png" alt="Images"> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 px-lg-5">
            <div class="section_content">
              <div class="section_title">
                <h2  style="color:rgb(45, 58, 121);">{{'plateforme4' | translate}}</h2>
               
              </div>
            </div>
            <!-- <div class="row mt-5">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <img src="../assets/img/duplex.png" class="card-img-top" alt="...">
                    <h4 class="card-title mt-3">Duplex Layout</h4>
                    <p class="card-text mt-3 mb-2">We have a lot of duplex and luxury rooms for you and you can easily buy
                      this.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <img src="../assets/img/verified.png" class="card-img-top" alt="...">
                    <h4 class="card-title mt-3">High-Level Security</h4>
                    <p class="card-text mt-3 mb-2">We have a lot of duplex and luxury rooms for you and you can easily buy
                      this.</p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
  
    </section>
    <app-footer></app-footer>
  </main>