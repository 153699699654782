import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { NgxUiLoaderModule } from 'ngx-ui-loader';
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { AboutAlkiraComponent } from './about-alkira/about-alkira.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PricingComponent } from './pricing/pricing.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TranslateModule,TranslateLoader } from  '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    AboutAlkiraComponent,
    LegalNoticeComponent,
    TermsOfServicesComponent,
    FooterComponent,
    HeaderComponent,
    PagenotfoundComponent,
    PricingComponent,
    SignUpComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    HttpClientModule,
    CommonModule,
    NgxUiLoaderModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]

      }
    }),
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'en' // 'de' for Germany, 'fr' for France ...
    //  },
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'en' // 'de' for Germany, 'fr' for France ...
   },],
  bootstrap: [AppComponent]
})
export class AppModule { }
