import { Component } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landing-page';

  constructor(

    private translate:TranslateService
    ) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('fr');
    
      let lang=window.localStorage.getItem('app_language');
    
      if(lang==null){
        this.translate.use('fr');
        window.localStorage.setItem('app_language','fr');
      }else{
        this.translate.use(lang);
      }
    }

}
